
html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.root {
  width: 100%;
  height: 100%;
}